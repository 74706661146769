import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

const OurBoat = () => {
  return (
    <Layout>
      <SEO title={"Our boat"} />
      <div>Our boat</div>
    </Layout>
  );
};

export default OurBoat;
